import {
    AppBar,
    Card,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    Toolbar,
    Typography,
    makeStyles,
    styled,
} from '@material-ui/core';
import ReferralService from '../../services/referralService';
import { abbreviateAddress, useIsExtensionWidth } from '../../utils/utils';
import strings from '../../localization';
import { useWallet } from '../../utils/wallet';
import { useMemo } from 'react';
import CopyableDisplay from '../CopyableDisplay';
import { tableCellClasses, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { EXPLORER_URL, REFERRAL_BACKEND_URL } from '../../utils/env-variables';
import moment from 'moment';
import { useDomichainExplorerUrlSuffix } from '../../utils/connection';

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
    },
    copyContainer: {
        width: "100%",
        marginBottom: theme.spacing(3),
    },
    copyLinkLoading: {
        width: "100%",
        padding: theme.spacing(1),
    },
    guide: {
        fontSize: 16,
        marginBottom: theme.spacing(1.5),
    },
    levelHeader: {
        fontSize: 20,
        fontWeight: "bold",
    },
}));

const referralService = new ReferralService();

export default function ReferralForm() {
    const classes = useStyles();
    const wallet = useWallet();
    const isExtensionWidth = useIsExtensionWidth();
    const userName = useMemo(() => wallet.publicKey.toString(), [wallet]);
    const link = useMemo(() => referralService.getReferralLink(userName), [userName]);

    return (
        <Paper>
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        style={{ flexGrow: 1, fontSize: isExtensionWidth && '1rem' || undefined }}
                        component="h2"
                    >
                        {strings.yourReferralLink}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Card className={classes.card}>
                <div className={classes.copyContainer}>
                    <CopyableDisplay
                        value={link}
                        label={strings.referral.referralLinkLabel}
                        autoFocus={false}
                        qrCode
                    />
                </div>

                <Typography color="textPrimary" align="center" className={classes.guide}>
                    {strings.referral.guide1}
                </Typography>

                <Typography color="textPrimary" align="center" className={classes.guide}>
                    {strings.referral.guide2}
                </Typography>

                <Typography
                    color="textSecondary"
                    align="center"
                    className={classes.levelHeader}
                >
                    {strings.referral.earningByLevels.replace("{coin}", "DOMI")}
                </Typography>
                <ReferralTable />
            </Card>
        </Paper>
    );
}

const StyledTableCell: (props: TableCellProps) => JSX.Element = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.divider}`,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
    },
})) as any;

type Referees = {
    domichainAddress: string,
    createdAt: string,
}[];
type Payouts = {
    payoutAmount: number,
    stakeAmount: number,
    stakeOwnerAddress: string,
    txSignature: string,
    createdAt: string,
}[];
type TableData = {
    date: moment.Moment,
    amount: string | null,
    txSignature: string | null,
    domichainAddress: string,
    stakeAmount: string | null,
}[];

function ReferralTable() {
    const wallet = useWallet();
    const urlSuffix = useDomichainExplorerUrlSuffix();
    const domichainAddress = useMemo(() => wallet.publicKey.toBase58(), [wallet]);

    const { data: referees } = useQuery({
        queryKey: ['referralRefereesInfo', domichainAddress],
        queryFn: async () => {
            const response = await fetch(
                `${REFERRAL_BACKEND_URL}/v1/new-stake/referees/${domichainAddress}`,
            );

            if (response.ok && response.status === 200) {
                const responseJson = await response.json();
                if (responseJson.status === 'success') {
                    return responseJson.data.referees as Referees;
                }
            }
        },
    });

    const { data: payouts } = useQuery({
        queryKey: ['referralPayoutsInfo', domichainAddress],
        queryFn: async () => {
            const response = await fetch(
                `${REFERRAL_BACKEND_URL}/v1/new-stake/referral-reward-payouts/${domichainAddress}`,
            );

            if (response.ok && response.status === 200) {
                const responseJson = await response.json();
                if (responseJson.status === 'success') {
                    return responseJson.data.payouts as Payouts;
                }
            }
        },
    });

    const data: TableData = useMemo(() => {
        const data: TableData = [];

        referees?.forEach(referee => {
            data.push({
                date: moment(referee.createdAt),
                amount: null,
                txSignature: null,
                domichainAddress: referee.domichainAddress,
                stakeAmount: null,
            });
        });
        payouts?.forEach(payout => {
            const amountDomi = balanceAmountToTrailingUserAmount(payout.payoutAmount, 9);
            const stakeAmountDomi = balanceAmountToTrailingUserAmount(payout.stakeAmount, 9);
            data.push({
                date: moment(payout.createdAt),
                amount: amountDomi,
                txSignature: payout.txSignature,
                domichainAddress: payout.stakeOwnerAddress,
                stakeAmount: stakeAmountDomi,
            });
        });

        data.sort(({ date: a }, { date: b }) => a.isSame(b) ? 0 : (a.isBefore(b) ? 1 : -1));

        return data;
    }, [referees, payouts]);

    return (
        <>
            <TableContainer component={Paper} style={{ marginTop: 36 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {[
                                strings.referralTable.date,
                                strings.referralTable.event,
                                strings.referralTable.walletId,
                                strings.referralTable.stakeAmount,
                            ].map(
                                (it) => (
                                    <StyledTableCell align="center">{it}</StyledTableCell>
                                ),
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                <StyledTableCell
                                    align="center"
                                    title={row.date.format('lll')}
                                >
                                    {row.date.fromNow()}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.amount === null ? strings.referralTable.registered : (
                                        <Link
                                            target="_blank"
                                            rel="noopener"
                                            href={
                                                `${EXPLORER_URL}/tx/${row.txSignature || ""}` + urlSuffix
                                            }
                                        >
                                            {strings.formatString(strings.referralTable.bonusDomi, row.amount)}
                                        </Link>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Link
                                        target="_blank"
                                        rel="noopener"
                                        href={
                                            `${EXPLORER_URL}/account/${row.domichainAddress}` + urlSuffix
                                        }
                                    >
                                        {abbreviateAddress(row.domichainAddress)}
                                    </Link>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.stakeAmount === null ? "-" : (
                                        strings.formatString(strings.referralTable.domi, row.stakeAmount)
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

// Format trailing zeros 0.000 -> 0.0
function removeTrailingZeros(formattedAmount: string): string {
    if (formattedAmount.indexOf('.') === -1) {
        return formattedAmount;
    }
    while (formattedAmount.slice(-1) === '0') {
        formattedAmount = formattedAmount.slice(0, formattedAmount.length - 1);
    }
    if (formattedAmount.slice(-1) === '.') {
        return `${formattedAmount}0`;
    }
    return formattedAmount;
}

function balanceAmountToTrailingUserAmount(balanceAmount: number, decimals: number): string {
    const balanceFormat = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        useGrouping: true,
    });
    const formattedAmount = balanceFormat.format(
        balanceAmount / Math.pow(10, decimals),
    );
    return removeTrailingZeros(formattedAmount);
}
