import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTokenAccounts } from '../../../utils/tokens/hooks';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import UsdtExchangeDialog from '../../bridge/usdt/UsdtExchangeDialog';
import strings from '../../../localization';

interface PurchaseCardProps {
  stakingBackendUrl: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(3),
  },
  list: {
    marginLeft: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
  }
}));

export function PurchaseCard({ stakingBackendUrl }: PurchaseCardProps) {
  const classes = useStyles();
  const [usdtExchangeDialogOpen, setUsdtExchangeDialogOpen] = useState(false);
  const { data: usdtTokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const handleBuyClick = () => {
    const usdtTotalAmount = usdtTokenAccounts?.reduce((acc, curr) => {
      const tokenAmount = curr.account.data.parsed.info.tokenAmount;
      return acc + tokenAmount.uiAmount;
    }, 0) ?? 0;

    setUsdtExchangeDialogOpen(true);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h6">{strings.buyDomiItemTitle}</Typography>
          <Typography variant="body2" color="textSecondary">
            {strings.purchase.subtitle}
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography variant="body2" color="textSecondary">
          {strings.purchase.afterPurchase}
          </Typography>
          <div className={classes.list}>
            <Typography variant="body2" className={classes.listItem}>
              • {strings.purchase.dominPerUsdt}
            </Typography>
            <Typography variant="body2" className={classes.listItem}>
              • {strings.purchase.sesaAirdrop}
            </Typography>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleBuyClick}
          className={classes.button}
        >
          {strings.buyDomiButton}
        </Button>
      </Paper>

      <UsdtExchangeDialog
        open={usdtExchangeDialogOpen}
        onClose={() => setUsdtExchangeDialogOpen(false)}
      />
    </>
  );
}