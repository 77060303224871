import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Redeem } from '@material-ui/icons';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment/min/moment-with-locales';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import strings from '../localization';
import AirdropService, { RateLimitError } from '../services/airdropService';
import { chromeLocalStorage } from '../utils/chrome-storage-polyfill';
import { useLocalStorage } from '../utils/hookHelpers';
import { useWallet } from '../utils/wallet';
import { TelegramPrefetchProps } from './BalancesList/TelegramSubscribeDialog';

const DAY_IN_MILLS = 24 * 60 * 60 * 1000;

export interface WelcomeAirdropProps {
  onShowSubscription: () => void;
}

interface AirdropStorageProps {
  timestamp: number;
  count: number;
}

export function WelcomeAirdrop({ onShowSubscription }: WelcomeAirdropProps) {
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  const [lastRequestTimestamp, setLastRequestTimestamp] = useState<number | undefined>();
  const [requestCount, setRequestCount] = useState(0);
  const [accepted, setAccepted] = useState(false);

  const airdropService = new AirdropService();

  const canRequestAgain =
    requestCount < 5 &&
    (lastRequestTimestamp
      ? lastRequestTimestamp + DAY_IN_MILLS <= Date.now()
      : true);

  useLocalStorage('welcomeAirdropLastRequest', (value: AirdropStorageProps | undefined) => {
    setLastRequestTimestamp(value ? value.timestamp : undefined);
    setRequestCount(value ? value.count : 0);
  });

  useLocalStorage('tmpPrefetch', (props: TelegramPrefetchProps | undefined) => {
    const created = props ? props.created > 0 : false;
    setAccepted(created);
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      return await airdropService.register(wallet);
    },
    onSuccess: () => {
      enqueueSnackbar(strings.welcomeAirdropSnackbar.replace('%s', '1'), {
        variant: 'success',
        autoHideDuration: 5000,
      });
    },
    onError: (error: any) => {
      enqueueSnackbar(error.message ?? strings.airdropIsAlreadyDoneToday, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
    onSettled: (data, error) => {
      if (data || error instanceof RateLimitError) {
        const storageProps: AirdropStorageProps = {
          timestamp: Date.now(),
          count: requestCount + 1,
        };

        chromeLocalStorage.set({
          welcomeAirdropLastRequest: storageProps,
        });
      }
    },
  });

  const onClick = () => {
    if (accepted) {
      mutate();
    } else {
      onShowSubscription();
    }
  };

  if (requestCount >= 5) {
    return null;
  }

  return (
    <ListItem
      button
      onClick={() => onClick()}
      disabled={!canRequestAgain || isLoading}
    >
      <ListItemIcon>
        <Redeem />
      </ListItemIcon>

      <ListItemText
        primary={strings.welcomeAirdropTitle}
        primaryTypographyProps={{ style: { fontWeight: '600' } }}
        secondary={
          canRequestAgain
            ? strings.welcomeAirdropAvailable
            : strings.welcomeAirdropUnavailable.replace(
              '%s',
              moment().to(lastRequestTimestamp! + DAY_IN_MILLS),
            )
        }
      />
    </ListItem>
  );
}
