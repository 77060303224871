import {
  AppBar,
  Card,
  DialogContent,
  Paper,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import StakingService, { StakingMode } from '../../services/stakingService';
import { useIsExtensionWidth } from '../../utils/utils';
import { NewDelegateStake } from '../staking/surfaces/NewDelegateStake';
import { PurchaseCard } from '../staking/surfaces/PurchaseCard';
import { STAKING_BACKEND_URL } from '../../utils/env-variables';
import useLocalStorageState from 'use-local-storage-state';
import { useQuery } from '@tanstack/react-query';
import { useWallet } from '../../utils/wallet';
import { PublicKey, ParsedAccountData } from '@solana/web3.js';
import { match, P } from 'ts-pattern';
import LoadingIndicator from '../LoadingIndicator';
import { useConnection } from '../../utils/connection';
import NewActiveStakeInfo from '../staking/surfaces/NewActiveStakeInfo';

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
}));

const STAKING_MODE: StakingMode = 'new';
//const TEST_OWNER_AMOUNT = '9pCQD6YNJYaHUb9Cgm4HVbRg55AShqCNnDb5W7t2h6Q6';
//const TEST_STAKE_ACCOUNT = 'DbgRWKtu9dKYdaiFuyEd48fd9zk7AB3SAZRL9ygUMgC';
//const TEST_OWNER_AMOUNT = 'GVnvSp7LeHRq2qUcV63ZVr6Ts4yu7psjgxL5dpgw764p';
//const TEST_STAKE_ACCOUNT = '7qNyaA8dpsKZyKv7r6wpjTiBfht5AwMH5KKA7nWquutK';
const STAKE_PROGRAM_ID = 'Stake11111111111111111111111111111111111111';
const STAKE_LOCKUP_CUSTODIAN = '11111111111111111111111111111111';

const isParsedAccountData = (data: Buffer | ParsedAccountData): data is ParsedAccountData => {
  return (data as ParsedAccountData).parsed !== undefined;
};

export default function StakingForm() {
  const classes = useStyles();
  const isExtensionWidth = useIsExtensionWidth();
  const wallet = useWallet();
  const connection = useConnection();

  const [stakingBackendUrl] = useLocalStorageState('stackingBackendUrl', {
    defaultValue: STAKING_BACKEND_URL,
  });

  const stakingMode = STAKING_MODE;
  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);

  // Get the stake account
  const { data: stakeAccount } = useQuery<PublicKey>({
    queryKey: ['stakeAccount', stakingMode],
    queryFn: () => stakingService.getStakeAccount(),
  }); 

  // Get stake activation state
  const { data: stakeAccountInfo, isFetched: activationFetched } = useQuery({
    queryKey: ['stakeAccountInfo', stakeAccount?.toBase58()],
    queryFn: async () => {
      try {
        return await connection.getStakeActivation(stakeAccount as PublicKey);
      } catch {
        return null;
      }
    },
    enabled: Boolean(stakeAccount),
    refetchInterval: 30 * 1000,
  });

  // Get detailed account info 
  const { data: detailedAccountInfo, isFetched: detailsFetched } = useQuery({
    queryKey: ['detailedAccountInfo', stakeAccount?.toBase58()],
    queryFn: async () => {
      if (!stakeAccount) return null;
      try {
        const accountInfo = await connection.getParsedAccountInfo(stakeAccount);
        if (!accountInfo.value) return null;

        const data = accountInfo.value.data as ParsedAccountData;
        if (!data.parsed) return null;

        return {
          parsed: data.parsed,
          owner: accountInfo.value.owner.toBase58()
        };
      } catch (error) {
        console.error('Error fetching detailed account info:', error);
        return null;
      }
    },
    enabled: Boolean(stakeAccount),
  });

  const { data: stakingPlan } = useQuery({
    queryKey: ['stakingPlan', stakingBackendUrl],
    queryFn: async () => {
      return await stakingService.fetchNewStakePlan();
    },
    retry: 5,
    retryDelay: 5000,
  });

  const isFetched = activationFetched && detailsFetched;

  // Check if account is initialized but not delegated
  const isInitializedNotDelegated = detailedAccountInfo && 
    detailedAccountInfo.owner === STAKE_PROGRAM_ID &&
    detailedAccountInfo.parsed.type === "initialized" &&
    detailedAccountInfo.parsed.info.meta && 
    detailedAccountInfo.parsed.info.meta.authorized &&
    detailedAccountInfo.parsed.info.meta.authorized.staker === wallet.publicKey?.toBase58() &&
    detailedAccountInfo.parsed.info.meta.lockup &&
    detailedAccountInfo.parsed.info.meta.lockup.custodian === STAKE_LOCKUP_CUSTODIAN &&
    !detailedAccountInfo.parsed.info.stake;

  /*   // Debug logging
  console.log('Stake Account Details:', {
    stakeAccount: stakeAccount?.toBase58(),
    owner: detailedAccountInfo?.owner,
    isProgramMatch: detailedAccountInfo?.owner === STAKE_PROGRAM_ID,
    isInitialized: detailedAccountInfo?.parsed.type === "initialized",
    hasMeta: detailedAccountInfo?.parsed.info.meta,
    staker: detailedAccountInfo?.parsed.info.meta?.authorized?.staker,
    isStakerMatch: detailedAccountInfo?.parsed.info.meta?.authorized?.staker === wallet.publicKey?.toBase58(),
    lockupCustodian: detailedAccountInfo?.parsed.info.meta?.lockup?.custodian,
    hasStake: detailedAccountInfo?.parsed.info.stake,
    isInitializedNotDelegated
  });
    */

  const stakingPlanLoaded = !!stakingPlan;

  return (
    <Paper>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography
            variant="h6"
            style={{ flexGrow: 1, fontSize: isExtensionWidth && '1rem' || undefined }}
            component="h2"
          >
            {"Staking"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Card className={classes.card}>
        {!isFetched || !stakingPlanLoaded ? (
          <DialogContent>
            <LoadingIndicator delay={0} />
          </DialogContent>
        ) : match([stakeAccountInfo, isInitializedNotDelegated])
          .with([P.not(P.nullish), false], () => (
            <NewActiveStakeInfo
              stakeAccount={stakeAccount!}
              stakeAccountInfo={stakeAccountInfo!}
              stakingBackendUrl={stakingBackendUrl!}
              stakingMode={stakingMode}
            />
          ))
          .with([P.any, true], () => (
            <>
              <PurchaseCard stakingBackendUrl={stakingBackendUrl} />
              <NewDelegateStake
                stakingPlan={stakingPlan!}
                stakingBackendUrl={stakingBackendUrl}
                stakingMode={stakingMode}
                isInitializedNotDelegated={true}
              />
            </>
          ))
          .otherwise(() => (
            <>
              <PurchaseCard stakingBackendUrl={stakingBackendUrl} />
              <NewDelegateStake
                stakingPlan={stakingPlan!}
                stakingBackendUrl={stakingBackendUrl}
                stakingMode={stakingMode}
                isInitializedNotDelegated={false}
              />
            </>
          ))}
      </Card>
    </Paper>
  );
}