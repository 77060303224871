import { DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import strings from '../../../localization';
import DialogForm from '../../DialogForm';


export interface UnstakeAlertDialogProps {
  open: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export default function UnstakeAlertDialog({
  open,
  onConfirm,
  onClose,
}: UnstakeAlertDialogProps) {
  return (
    <DialogForm
      open={open}
      onClose={() => onClose && onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {strings.confirmUnstakeTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {strings.confirmUnstakeDescription}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose()}>{strings.cancel}</Button>
        <Button color="primary"
                onClick={() => onConfirm && onConfirm()}>
          {strings.unstake}
        </Button>
      </DialogActions>
    </DialogForm>
  )
}
