const english = {
  applicationName: 'Domichain Wallet',
  unlockWallet: 'Unlock wallet',
  choosePassword: 'Choose a Password (Optional)',
  pickPassword: 'Optionally pick a password to protect your wallet.',
  ifForgetPassword:
    'If you forget your password you will need to restore your wallet using your seed words.',
  restoreExistingWallet: 'Restore Existing Wallet',
  createNewWallet: 'Create New Wallet',
  createToHold: 'Create a new wallet to hold Domichain and DPL tokens',
  seedSafePlace:
    'Please write down the following twelve words and keep them in a safe place:',
  privateKeysStored:
    "Your private keys are only stored on your current computer or device. You will need these words to restore your wallet if your browser's storage is cleared or your device is damaged or lost.",
  iHaveSaved: 'I have saved these words in a safe place.',
  downloadMnemonic: 'Download Backup Mnemonic File (Required)',
  pleaseReenter:
    'Please re-enter your seed phrase to confirm that you have saved it.',
  pleaseType: 'Please type your seed phrase to confirm',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  back: 'Back',
  createWallet: 'Create Wallet',
  unlockingWallet: 'Unlocking wallet...',
  walletUnlocked: 'Wallet unlocked',
  password: 'Password',
  keepWalletUnlocked: 'Keep wallet unlocked',
  restoreExisting: 'Restore Existing Wallet',
  restoreUsing:
    'Restore your wallet using your twelve or twenty-four seed words. Note that this will delete any existing wallet on this device.',
  noHardwareWallet:
    'Do not enter your hardware wallet seedphrase here. Hardware wallets can be optionally connected after a web wallet is created.',
  mnemonicFailed:
    'Mnemonic validation failed. Please enter a valid BIP 39 seed phrase.',
  seedWords: 'Seed Words',
  newPasswordOptional: 'New Password (Optional)',
  cancel: 'Cancel',
  next: 'Next',
  addAccount: 'Add Account',
  name: 'Name',
  importPrivateKey: 'Import private key',
  pastePrivateKey: 'Import private key',
  add: 'Add',
  close: 'Close',
  addCustomNetwork: 'Add a custom network',
  url: 'URL',
  done: 'done',
  continue: 'Continue',
  export: 'Export',
  delete: 'Delete',
  send: 'Send',
  receive: 'Receive',
  unlock: 'Unlock',
  confirmAddress: 'Confirm your wallet address',
  checkLedger:
    "Check your ledger and confirm the address displayed is the address chosen. Then click 'done'.",
  addHardwareWallet: 'Add hardware wallet',
  connectLedger:
    "Connect your ledger and open the Domichain application. When you are ready, click 'continue'.",
  loadingFromHardwareWallet: 'Loading accounts from your hardware wallet',
  copied: 'Copied',
  copyToClipboard: 'Copy to Clipboard',
  editAccountName: 'Edit Account Name',
  auxiliaruAccount: 'This is an auxiliary token account.',
  viewOnDomiexplorer: 'View on Domiexplorer',
  viewDetails: 'View Details',
  tokenName: 'Token Name',
  tokenSymbol: 'Token Symbol',
  mainAccount: 'Main account',
  account: 'Account',
  refresh: 'Refresh',
  wontRecover:
    'You will not be able to recover the current accounts without the seed phrase, and the account private key. This action will delete all current accounts from your browser.',
  perventLoss:
    "To prevent loss of funds, please ensure you have the seed phrase and the private key for all current accounts. You can view it by selecting 'Export Mnemonic' in the user menu.",
  enterSeedPhase: 'Please type your seed phrase to confirm',
  deleteAndLogout: 'Delete Mnemonic & Log Out',
  editAccount: 'Edit Account',
  addressReceive: 'This address can be used to receive',
  depositAddress: 'Deposit Address',
  enterDomichainAddress: 'Enter Domichain Address',
  enterDPLAddress: 'Enter DPL token or Domichain address',
  amount: 'Amount',
  max: 'Max',
  recipientAddress: 'Recipient Address',
  use1: 'Please use the',
  use2: 'Wormhole Portal Bridge',
  use3: 'to bridge your assets.',
  save: 'SAVE',
  exportAccount: 'Export Account',
  privateKey: 'Private Key',
  reveal: 'Reveal',
  mnemonic: 'Mnemonic',
  connect: 'Connect',
  exportMnemonic: 'Export Mnemonic',
  importHardwareWallet: 'Import Hardware Wallet',
  selectAccount: 'Select Account',
  editCustomEndpoint: 'Edit Custom Endpoint',
  addCustomEndpoint: 'Add Custom Endpoint',
  hardwareWallet: 'Hardware wallet',
  selectNetwork: 'Select Network',
  selectLanguage: 'Select Language',
  connections: 'Connections',
  manageConnections: 'Manage Connections',
  wallet: 'Wallet',
  walletBalance: 'Wallet Balance',
  expandView: 'Expand View',
  appName: 'DWallet',
  disconnect: 'Disconnect',
  connectedDapps: 'Connected Dapps',
  yourReferralLink: 'Your referral link',
  sendRequest: 'Send Request',
  waitingForConfirmation: 'Waiting for Confirmation',
  withdrawFunds: 'Withdraw Funds',
  deposit: 'Deposit',
  metamaskConnected: 'Metamask connected',
  transactionPending: 'Transaction Pending',
  language: 'Language',
  confirmMnenonic: 'Confirm Mnemonic',
  creatingWallet: 'Creating Wallet...',
  walletCreated: 'Wallet Created!',
  time: 'Time',
  txHash: 'Tx Hash',
  type: 'Type',
  transfer: 'Transfer',
  other: 'Other',
  approve: 'Approve',
  deny: 'Deny',
  connectWalletDialogQuestion:
    'Allow this Dapp to access your Domichain account?',
  connectWalletDialogNotice:
    'All transactions will be auto-approved, only connect with sites you trust. You can always change this in "Connected Dapps" section.',
  allowTransactionDialogQuestion: 'Allow this Dapp to send a transaction?',
  airdrop: 'Airdrop',
  addressHasNoFunds: "This address has no funds. Are you sure it's correct?",
  mintNewToken: 'Mint New Token',
  mintNewTokenDescription:
    'Generate and receive a new valueless token. This operation requires DOMI to cover transaction fees.',
  tokenDecimals: 'Token Decimals',
  stake: 'Stake',
  delegate: 'Delegate',
  yourStake: 'Your Stake',
  stakeAccount: 'Stake Account',
  status: 'Status',
  balance: 'Balance',
  activeStake: 'Active Stake',
  inactiveStake: 'Inactive Stake',
  reward: 'Reward',
  unstake: 'Unstake',
  claim: 'Claim',
  availableForWithdraw: 'Available For Withdraw',
  totalRewardWithdrawn: 'Total Reward Withdrawn',
  totalBalanceExpected: 'Total Balance Expected',
  withdrawStake: 'Withdraw Stake',
  startStaking: 'Start Staking',
  stakingChoosePlanDescription:
    'Start staking DOMI and earn rewards by choosing one of the plans below.',
  stakingDepositDescription:
    "Deposit USDT on the BNB Smart Chain to the given deposit address with the specified token amount.\n\nOnce deposited, you'll automatically become eligible for staking.",
  stakingDelegateDescription:
    "Choose how much DOMI you'd like to stake with your validator.",
  purchase: {
    title: 'Purchase',
    subtitle: 'Buy DOMI tokens directly and start earning rewards by staking them.',
    afterPurchase: 'After purchase, you\'ll immediately receive:',
    dominPerUsdt: '1 DOMI for every 2 USDTs',
    sesaAirdrop: 'Spending mimium of 50 USDT will grant you 2,000 SESA tokens instantly'
  },
  newStakingDelegateDescription1: 'Lock Period: Lock your $DOMI for 180 days.',
  newStakingDelegateDescription2: 'Total Rewards:',
  newStakingDelegateDescription3: '  Existing $DOMI: Earn a total reward of 50% over the staking period.',
  newStakingDelegateDescription4: '  Newly Purchased $DOMI: Earn double rewards at 100%.',
  newStakingDelegateDescription5: 'Daily Withdrawals:',
  newStakingDelegateDescription6: '   Existing $DOMI: Withdraw 0.2% daily to your wallet or an exchange (minimum   withdrawal amount: 10).',
  newStakingDelegateDescription7: '   Newly Purchased $DOMI: Withdraw 0.47% daily.',
  newStakingDelegateDescription8: 'Post Lock Period: After 180 days, unlock the principal amount and the remaining 15% rewards, which can be transferred to your wallet.',
  newStakingMinDomiAmount: 'Minimum amount: {0} DOMI',
  dailyWithdrawal: 'Daily Withdrawal',
  totalReward: 'Total Reward',
  stakeWarning: "You are trying to stake more Domi than purchased, meaning you won't get the increased rewards. Try staking less or purchasing more Domi.",
  stakingActivatingDescription:
    'Your DOMI will begin earning rewards in the next couple days once the stake account becomes active.',
  stakingActiveDescription:
    "Your DOMI is currently staked with a validator. You'll need to unstake to access these funds.",
  stakingPlan: 'Plan',
  stakingAnnualFee: 'Annual Fee',
  stakingProduct: 'Product',
  stakingAnnualRoi: 'Annual ROI',
  stakingLimit: 'Staking Limit',
  stakingUrl: 'Staking URL',
  unlimited: 'Unlimited',
  validator: 'Validator',
  activating: 'Activating',
  active: 'Active',
  deactivating: 'Deactivating',
  inactive: 'Inactive',
  showDepositAddress: 'Show Deposit Address',
  confirmUnstakeTitle: 'Confirm Unstaking',
  confirmUnstakeDescription:
    "Are you sure you want to unstake? You'll stop earning any rewards, but will be able to withdraw your funds.",
  installPwaTitle: 'Install DWallet',
  installPwaDescription:
    'Install the app on your device to easily access it anytime.',
  installPwaStepOne: '1. Tap on the "Share" Button',
  installPwaStepTwo: '2. Select "Add to Home Screen"',
  installPwaStepThree: '3. Go to the Home Screen, find DWallet and open it.',
  redPacketTitle: 'Happy Lunar New Year!',
  redPacketAvailable: 'Press here to claim your free Red Packet gift',
  redPacketUnavailable: 'Come back %s to claim another gift',
  redPacketSnackbar: "You've claimed %s DOMI from your Red Packet gift!",
  airdropIsAlreadyDoneToday: "Today's airdrop is already sent, come back tomorrow to claim it",
  errorOccurredTryAgainLater: 'An error occurred, try again later',
  settings: 'Settings',
  free: 'Free',
  welcomeAirdropTitle: 'Welcome to DWallet!',
  welcomeAirdropAvailable: 'Press here to get 1 free DOMI as a gift from us.',
  welcomeAirdropUnavailable: 'Come back %s to claim another gift.',
  welcomeAirdropSnackbar: "You've received %s DOMI as a welcome gift!",
  usdtDepositNotice:
    'This address can be used to receive USDT only from %s. Do not send tokens from other networks! After USDT is received you can use it to buy DOMI and receive a SESA airdrop',
  usdtContractAddress: 'USDT contract address:',
  exchange: 'Exchange',
  loading: 'Loading...',
  expectedFee: 'Expected fee',
  youWillReceive: 'You will receive',
  selectTheFee: 'Select the fee',
  fastest: 'Fastest',
  normal: 'Normal',
  economy: 'Economy',
  sendingTransaction: 'Sending transaction...',
  transactionConfirmed: 'Transaction confirmed',
  failedToSignAndSendAMultisigTransaction: 'Failed to sign and send a multisig transaction',
  noAccountsFound: 'No accounts found',
  requestFailed: 'Request failed',
  noFeeRates: 'No fee rates',
  insufficientFunds: 'Insufficient funds',
  tenMinutes: '~10min',
  thirtyMinutes: '~30min',
  oneHour: '~1h',
  buyDomiItemTitle: 'Buy DOMI',
  buyDomiItemSubtitle: 'Deposit USDT to buy DOMI',
  buyDomiButton: 'Buy DOMI',
  buyDomiDialogTitle: 'Buy DOMI',
  buyDomiMinimumUsdtAmount: 'Minimum USDT amount: {0} USDT',
  buyDomiReceiveNotice: 'After exchange, you\'ll immediately receive 2,000 SESA tokens and 1 DOMI for every 2 USDTs. Newly purchased DOMI qualifies for higher staking rewards of 100% for 180 days.',
  buyButton: 'Buy',
  telegramSubscriptionTitle: 'Subscribe to our social networks',
  telegramSubscriptionLink: 'Click to open in Telegram',
  telegramSubscriptionLink2: 'Telegram community link:',
  telegramInstructions1: "If you don't have Telegram:",
  telegramInstructions2: 'Install Telegram on your device',
  telegramInstructions3: 'or scan the QR code with another device with Telegram',
  telegramInstructions4: 'Click on the link or open it in your browser',
  telegramInstructions5: 'Press "Start" button to start chatting with out Telegram bot',
  telegramInstructions6: 'Follow the instructions of our bot to join out Telegram community',
  telegramInstructions7: 'Go back to DWallet and press the "Continue" button',
  subscribeTwitterInstructions: 'Follow our community on Twitter:',
  twitterSubscriptionLink: 'Click here to open on Twitter and then click "Follow" button',
  referral: {
    title: "Referral",
    description: "Refer friends to earn even more rewards!",
    totalReferral: "Your total referrals",
    referralReward: "Your {coin} referral reward",
    pendingReward: "Pending {coin} reward",
    claim: "Claim Referral Rewards",
    copy: "Copy",
    referralLinkLabel: "Referral link",
    referralLinkLoading: "Loading referral link...",
    guide1: "1. Send your referral link to your friends.",
    guide2: "2. When they stake, you earn additional rewards.",
    earningByLevels: "Earn referral reward at 1-10%",
    teamRewardsByLevels: "Team Bonus",
    updateHeader: "Update your information",
    update: "Update",
  },
  referralTable: {
    date: "Date",
    event: "Event",
    walletId: "Wallet ID",
    stakeAmount: "Stake Amount",
    registered: "registered",
    bonusDomi: "bonus {0} DOMI",
    domi: "{0} DOMI",
  },
};

export default english;
