import strings from '../localization';
import { REFERRAL_BACKEND_URL } from '../utils/env-variables';

export default class ReferralAPI {
  async getDomiUser(domichainAddress: string): Promise<any | null> {
    const response = await fetch(
      `${REFERRAL_BACKEND_URL}/v1/users/${domichainAddress}`,
    );

    if (response.status === 200) {
      const { status, data } = await response.json();
      if (status === "success") {
        return data;
      }
    } else if (response.status === 404) {
      return null;
    }
    throw new Error(strings.errorOccurredTryAgainLater);
  }

  async registerDomiUser(domichainAddress: string, sponsorRef?: string | null,
                         email?: string | null
  ): Promise<any> {
    const response = await fetch(
      `${REFERRAL_BACKEND_URL}/v1/users`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          domichainAddress,
          sponsorRef,
          email,
          // profile: "",
        }),
      },
    );

    if (response.status === 200) {
      const { status, data } = await response.json();
      if (status === "success") {
        return data;
      }
    }
    throw new Error(strings.errorOccurredTryAgainLater);
  }
}
