import { DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useQueryClient } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';
import { useState } from 'react';
import strings from '../../../localization';
import StakingService, { StakingMode } from '../../../services/stakingService';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import StakingSettingsDialog from '../StakingSettingsDialog';
import { StakingPlan } from './ActivateStake';
import { RewardInfo } from './StakeAmountInfo';

const MINIMUM_DOMI_STAKE_AMOUNT = 500;

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  subSection: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.main,
  }
}));

interface NewDelegateStakeProps {
  stakingPlan: StakingPlan;
  stakingBackendUrl: string;
  stakingMode: StakingMode;
  isInitializedNotDelegated?: boolean;
}

export function NewDelegateStake({ 
  stakingPlan,
  stakingBackendUrl,
  stakingMode,
  isInitializedNotDelegated = false
}: NewDelegateStakeProps) {
  const classes = useStyles();
  const wallet = useWallet();
  const connection = useConnection();
  const balanceInfo = useBalanceInfo(wallet.publicKey);
  const queryClient = useQueryClient();

  const [sendTransaction, sending] = useSendTransaction();

  const [tokenAmount, setTokenAmount] = useState<string>('');

  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);

  if (!stakingPlan.validatorVotePubkey || !stakingPlan.lockupPeriodSeconds) {
    return (<>Failed to fetch staking plan info!</>);
  }
  const votePubkey = new PublicKey(stakingPlan.validatorVotePubkey!);
  const stakingLimit = stakingPlan.stakingLimit;
  const lockupPeriodSeconds = stakingPlan.lockupPeriodSeconds!;

  const delegateStake = async () => {
    const stakeAccount = await stakingService.getStakeAccount();

    return await stakingService.delegateStake(stakeAccount, tokenAmount, stakingLimit,
      votePubkey, lockupPeriodSeconds);
  };

  const handleOnPress = async () => {
    const stakeAccount = await stakingService.getStakeAccount();
  
    if (isInitializedNotDelegated) {
      // Only delegate if account is already initialized
      sendTransaction(stakingService._delegateStake(stakeAccount, votePubkey), {
        onSuccess: async () => {
          try {
            // Attempt to register the stake in the DB - May need to add backfill attempt here
            const { stakeAmount } = await stakingService.registerStake(stakeAccount);
  
            localStorage.setItem(
              `initialStake_${stakeAccount.toBase58()}`,
              stakeAmount.toString(),
            );
          } finally {
            queryClient.refetchQueries({
              queryKey: ['stakeAccountInfo', stakeAccount.toBase58()],
            });
          }
        },
      });
    } else {
      // Normal flow for new stake accounts
      sendTransaction(delegateStake(), {
        onSuccess: async () => {
          try {
            const { stakeAmount } = await stakingService.registerStake(stakeAccount);
  
            localStorage.setItem(
              `initialStake_${stakeAccount.toBase58()}`,
              stakeAmount.toString(),
            );
          } finally {
            queryClient.refetchQueries({
              queryKey: ['stakeAccountInfo', stakeAccount.toBase58()],
            });
          }
        },
      });
    }
  };

  const parsedInputAmount = /^\d+\.?\d*$/.test(tokenAmount)
    ? new Decimal(tokenAmount)
    : new Decimal(0);

  const validAmount =
    parsedInputAmount.greaterThanOrEqualTo(MINIMUM_DOMI_STAKE_AMOUNT) &&
    parsedInputAmount.lessThanOrEqualTo(balanceInfo?.amount / LAMPORTS_PER_SOL);

    const isDisabled = isInitializedNotDelegated ? sending : (!validAmount || sending);

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.startStaking}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText component="div">
          <div className={classes.section}>
            <Typography variant="subtitle1" className={classes.title}>
              {strings.newStakingDelegateDescription1}
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="subtitle1" className={classes.title}>
              {strings.newStakingDelegateDescription2}
            </Typography>
            <div className={classes.subSection}>
              <Typography variant="body2">
                {strings.newStakingDelegateDescription3}
              </Typography>
              <Typography variant="body2">
                {strings.newStakingDelegateDescription4}
              </Typography>
            </div>
          </div>

          <div className={classes.section}>
            <Typography variant="subtitle1" className={classes.title}>
              {strings.newStakingDelegateDescription5}
            </Typography>
            <div className={classes.subSection}>
              <Typography variant="body2">
                {strings.newStakingDelegateDescription6}
              </Typography>
              <Typography variant="body2">
                {strings.newStakingDelegateDescription7}
              </Typography>
            </div>
          </div>

          <div className={classes.section}>
            <Typography variant="body2">
              {strings.newStakingDelegateDescription8}
            </Typography>
          </div>
        </DialogContentText>
        <TextField
          label={strings.amount}
          fullWidth
          autoFocus
          variant="outlined"
          margin="normal"
          type="number"
          disabled={isInitializedNotDelegated}  
          InputProps={{
            endAdornment: !isInitializedNotDelegated ? (  
              <InputAdornment position="end">
                <Button
                  onClick={() =>
                    setTokenAmount(
                      balanceAmountToUserAmount(
                        Math.max(0, balanceInfo?.amount - LAMPORTS_PER_SOL),
                        balanceInfo?.decimals,
                      ),
                    )
                  }
                >
                  {strings.max}
                </Button>
                <Typography color="primary">
                  {balanceInfo?.tokenSymbol}
                </Typography>
              </InputAdornment>
            ) : null
          }}
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value.trim())}
          helperText={
            !isInitializedNotDelegated ? ( 
              <span
                onClick={() =>
                  setTokenAmount(
                    balanceAmountToUserAmount(
                      balanceInfo?.amount,
                      balanceInfo?.decimals,
                    ),
                  )
                }
              >
                {strings.max}:{' '}
                {balanceAmountToUserAmount(
                  balanceInfo?.amount,
                  balanceInfo?.decimals,
                )}
              </span>
            ) : null
          }
        />
        <DialogContentText>
          {strings.formatString(strings.newStakingMinDomiAmount, MINIMUM_DOMI_STAKE_AMOUNT)}
        </DialogContentText>
      </DialogContent>
      <RewardInfo amount={tokenAmount} walletAddress={wallet.publicKey.toBase58()} />

      <DialogActions>
      <Button color="primary" onClick={handleOnPress} disabled={isDisabled}>
          {isInitializedNotDelegated ? strings.delegate : strings.stake}
        </Button>
      </DialogActions>
    </>
  );
}

function balanceAmountToUserAmount(balanceAmount: number, decimals: number) {
  return (balanceAmount / Math.pow(10, decimals)).toFixed(decimals);
}
