/* global BigInt */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as bs58 from 'bs58';
import { Account, PublicKey, StakeProgram } from '@solana/web3.js';
import nacl from 'tweetnacl';
import {
  setInitialAccountInfo,
  useAccountInfo,
  useConnection,
} from './connection';
import {
  closeTokenAccount,
  createAndInitializeTokenAccount,
  createAssociatedTokenAccount,
  getOwnedTokenAccounts,
  nativeTransfer,
  signAndSendTransaction,
  transferTokens,
} from './tokens';
import {
  BTCI_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  USDT_PROGRAM_ID,
} from './tokens/instructions';
import {
  ACCOUNT_LAYOUT,
  parseMintData,
  parseTokenAccountData,
} from './tokens/data';
import { useListener, useRefEqual } from './utils';
import { useUnlockedMnemonicAndSeed, walletSeedChanged } from './wallet-seed';
import { WalletProviderFactory } from './walletProvider/factory';
import { getAccountFromSeed } from './walletProvider/localStorage';
import { useSnackbar } from 'notistack';
import strings from '../localization';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from './query-client';
import useLocalStorageState from 'use-local-storage-state';
import { getMetadataByMint } from './tokens/metadata';

const DEFAULT_WALLET_SELECTOR = {
  walletIndex: 0,
  importedPubkey: undefined,
  ledger: false,
};

export class Wallet {
  constructor(connection, type, args) {
    this.connection = connection;
    this.type = type;
    this.provider = WalletProviderFactory.getProvider(type, args);
  }

  static create = async (connection, type, args) => {
    const instance = new Wallet(connection, type, args);
    await instance.provider.init();
    return instance;
  };

  get publicKey() {
    return this.provider.publicKey;
  }

  get allowsExport() {
    return this.type === 'local';
  }

  getTokenAccountInfo = async (connection) => {
    let accounts = await getOwnedTokenAccounts(connection, this.publicKey);
    return accounts
      .map(({ publicKey, accountInfo }) => {
        setInitialAccountInfo(connection, publicKey, accountInfo);
        return { publicKey, parsed: parseTokenAccountData(accountInfo.data) };
      })
      .sort((account1, account2) =>
        account1.parsed.mint
          .toBase58()
          .localeCompare(account2.parsed.mint.toBase58()),
      );
  };

  getStakeAccount = async (seed) => {
    return await PublicKey.createWithSeed(
      this.publicKey,
      seed,
      StakeProgram.programId,
    );
  };

  createTokenAccount = async (tokenAddress) => {
    return await createAndInitializeTokenAccount({
      connection: this.connection,
      payer: this,
      mintPublicKey: tokenAddress,
      newAccount: new Account(),
    });
  };

  createAssociatedTokenAccount = async (dplTokenMintAddress) => {
    return await createAssociatedTokenAccount({
      connection: this.connection,
      wallet: this,
      dplTokenMintAddress,
    });
  };

  tokenAccountCost = async () => {
    return this.connection.getMinimumBalanceForRentExemption(
      ACCOUNT_LAYOUT.span,
    );
  };

  transferToken = async (
    source,
    destination,
    amount,
    mint,
    decimals,
    memo = null,
    overrideDestinationCheck = false,
    programId = TOKEN_PROGRAM_ID,
  ) => {
    if (source.equals(this.publicKey)) {
      if (memo) {
        throw new Error('Memo not implemented');
      }
      return this.transferSol(destination, amount);
    }
    const transaction = await transferTokens({
      connection: this.connection,
      owner: this,
      sourcePublicKey: source,
      destinationPublicKey: destination,
      amount,
      memo,
      mint,
      decimals,
      overrideDestinationCheck,
      programId,
    });
    return await signAndSendTransaction(this.connection, transaction, this, []);
  };

  transferSol = async (destination, amount) => {
    return nativeTransfer(this.connection, this, destination, amount);
  };

  closeTokenAccount = async (publicKey, skipPreflight = false) => {
    return await closeTokenAccount({
      connection: this.connection,
      owner: this,
      sourcePublicKey: publicKey,
      skipPreflight,
    });
  };

  signTransaction = async (transaction) => {
    return this.provider.signTransaction(transaction);
  };

  createSignature = async (message) => {
    return this.provider.createSignature(message);
  };
}

const WalletContext = React.createContext(null);

export function WalletProvider({ children }) {
  useListener(walletSeedChanged, 'change');
  const [{ mnemonic, seed, importsEncryptionKey, derivationPath }] =
    useUnlockedMnemonicAndSeed();
  const { enqueueSnackbar } = useSnackbar();
  const connection = useConnection();
  const [wallet, setWallet] = useState();

  // `privateKeyImports` are accounts imported *in addition* to HD wallets
  const [privateKeyImports, setPrivateKeyImports] = useLocalStorageState(
    'walletPrivateKeyImports',
    { defaultValue: {} },
  );
  // `walletSelector` identifies which wallet to use.
  let [walletSelector, setWalletSelector] = useLocalStorageState(
    'walletSelector',
    { defaultValue: DEFAULT_WALLET_SELECTOR },
  );
  const [_hardwareWalletAccount, setHardwareWalletAccount] = useState(null);

  // `walletCount` is the number of HD wallets.
  const [walletCount, setWalletCount] = useLocalStorageState('walletCount', {
    defaultValue: 1,
  });

  if (walletSelector.ledger && !_hardwareWalletAccount) {
    walletSelector = DEFAULT_WALLET_SELECTOR;
    setWalletSelector(DEFAULT_WALLET_SELECTOR);
  }

  useEffect(() => {
    (async () => {
      if (!seed) {
        return null;
      }
      let wallet;
      if (walletSelector.ledger) {
        try {
          const onDisconnect = () => {
            setWalletSelector(DEFAULT_WALLET_SELECTOR);
            setHardwareWalletAccount(null);
          };
          const args = {
            onDisconnect,
            derivationPath: walletSelector.derivationPath,
            account: walletSelector.account,
            change: walletSelector.change,
          };
          wallet = await Wallet.create(connection, 'ledger', args);
        } catch (e) {
          console.log(`received error using ledger wallet: ${e}`);
          let message = 'Received error unlocking ledger';
          if (e.statusCode) {
            message += `: ${e.statusCode}`;
          }
          enqueueSnackbar(message, { variant: 'error' });
          setWalletSelector(DEFAULT_WALLET_SELECTOR);
          setHardwareWalletAccount(null);
          return;
        }
      }
      if (!wallet) {
        const account =
          walletSelector.walletIndex !== undefined
            ? getAccountFromSeed(
                Buffer.from(seed, 'hex'),
                walletSelector.walletIndex,
                derivationPath,
              )
            : new Account(
                (() => {
                  const { nonce, ciphertext } =
                    privateKeyImports[walletSelector.importedPubkey];
                  return nacl.secretbox.open(
                    bs58.decode(ciphertext),
                    bs58.decode(nonce),
                    importsEncryptionKey,
                  );
                })(),
              );
        wallet = await Wallet.create(connection, 'local', { account });
      }
      setWallet(wallet);
    })();
  }, [
    connection,
    seed,
    walletSelector,
    privateKeyImports,
    importsEncryptionKey,
    setWalletSelector,
    enqueueSnackbar,
    derivationPath,
  ]);
  function addAccount({ name, importedAccount, ledger }) {
    if (importedAccount === undefined) {
      name && localStorage.setItem(`name${walletCount}`, name);
      setWalletCount(walletCount + 1);
    } else {
      const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
      const plaintext = importedAccount.secretKey;
      const ciphertext = nacl.secretbox(plaintext, nonce, importsEncryptionKey);
      // `useLocalStorageState` requires a new object.
      let newPrivateKeyImports = { ...privateKeyImports };
      newPrivateKeyImports[importedAccount.publicKey.toString()] = {
        name,
        ciphertext: bs58.encode(ciphertext),
        nonce: bs58.encode(nonce),
      };
      setPrivateKeyImports(newPrivateKeyImports);
    }
  }

  const getWalletNames = () => {
    return JSON.stringify(
      [...Array(walletCount).keys()].map((idx) =>
        localStorage.getItem(`name${idx}`),
      ),
    );
  };
  const [walletNames, setWalletNames] = useState(getWalletNames());
  function setAccountName(selector, newName) {
    if (selector.importedPubkey && !selector.ledger) {
      let newPrivateKeyImports = { ...privateKeyImports };
      newPrivateKeyImports[selector.importedPubkey.toString()].name = newName;
      setPrivateKeyImports(newPrivateKeyImports);
    } else {
      localStorage.setItem(`name${selector.walletIndex}`, newName);
      setWalletNames(getWalletNames());
    }
  }

  const [accounts, derivedAccounts] = useMemo(() => {
    if (!seed) {
      return [[], []];
    }

    const seedBuffer = Buffer.from(seed, 'hex');
    const derivedAccounts = [...Array(walletCount).keys()].map((idx) => {
      let address = getAccountFromSeed(
        seedBuffer,
        idx,
        derivationPath,
      ).publicKey;
      let name = localStorage.getItem(`name${idx}`);
      return {
        selector: {
          walletIndex: idx,
          importedPubkey: undefined,
          ledger: false,
        },
        isSelected: walletSelector.walletIndex === idx,
        address,
        name:
          idx === 0 ? strings.mainAccount : name || `${strings.account} ${idx}`,
      };
    });

    const importedAccounts = Object.keys(privateKeyImports).map((pubkey) => {
      const { name } = privateKeyImports[pubkey];
      return {
        selector: {
          walletIndex: undefined,
          importedPubkey: pubkey,
          ledger: false,
        },
        address: new PublicKey(bs58.decode(pubkey)),
        name: `${name} (imported)`, // TODO: do this in the Component with styling.
        isSelected: walletSelector.importedPubkey === pubkey,
      };
    });

    const accounts = derivedAccounts.concat(importedAccounts);
    return [accounts, derivedAccounts];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seed, walletCount, walletSelector, privateKeyImports, walletNames]);

  let hardwareWalletAccount;
  if (_hardwareWalletAccount) {
    hardwareWalletAccount = {
      ..._hardwareWalletAccount,
      selector: {
        walletIndex: undefined,
        ledger: true,
        importedPubkey: _hardwareWalletAccount.publicKey,
        derivationPath: _hardwareWalletAccount.derivationPath,
        account: _hardwareWalletAccount.account,
        change: _hardwareWalletAccount.change,
      },
      address: _hardwareWalletAccount.publicKey,
      isSelected: walletSelector.ledger,
    };
  }

  return (
    <WalletContext.Provider
      value={{
        wallet,
        seed,
        mnemonic,
        importsEncryptionKey,
        walletSelector,
        setWalletSelector,
        privateKeyImports,
        setPrivateKeyImports,
        accounts,
        derivedAccounts,
        addAccount,
        setAccountName,
        derivationPath,
        hardwareWalletAccount,
        setHardwareWalletAccount,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export function useWallet() {
  return useContext(WalletContext).wallet;
}

export function useWalletTokenAccounts() {
  const wallet = useWallet();
  const connection = useConnection();
  return useQuery({
    queryKey: [
      'getTokenAccountInfo',
      wallet.publicKey.toBase58(),
      connection.rpcEndpoint,
    ],
    queryFn: () => wallet.getTokenAccountInfo(connection),
  });
}

export function useWalletPublicKeys() {
  const { data: tokenAccountInfo, isFetched } = useWalletTokenAccounts();
  const publicKeys = useRefEqual(
    [
      ...(tokenAccountInfo
        ? tokenAccountInfo.map(({ publicKey }) => publicKey)
        : []),
    ],
    (oldKeys, newKeys) =>
      oldKeys.length === newKeys.length &&
      oldKeys.every((key, i) => key.equals(newKeys[i])),
  );
  return [publicKeys, isFetched];
}

export function refreshWalletPublicKeys(wallet) {
  queryClient.refetchQueries({ queryKey: ['getTokenAccountInfo'] });
}

export function useWalletAddressForMint(mint) {
  const { data } = useWalletTokenAccounts();
  return useMemo(
    () =>
      mint
        ? data
            ?.find((account) => account.parsed?.mint?.equals(mint))
            ?.publicKey.toBase58()
        : null,
    [data, mint],
  );
}

const DEFAULT_BALANCE_INFO = {
  amount: 0,
  decimals: 9,
  mint: null,
  owner: null,
  tokenName: 'DOMI',
  tokenSymbol: 'DOMI',
  tokenLogoUri: null,
};

const tokenPrograms = [TOKEN_PROGRAM_ID, BTCI_PROGRAM_ID, USDT_PROGRAM_ID];

export function useBalanceInfo(publicKey) {
  const connection = useConnection();
  const [accountInfo] = useAccountInfo(publicKey);
  const { mint, owner, amount } = tokenPrograms.some((programId) =>
    accountInfo?.owner.equals(programId),
  )
    ? parseTokenAccountData(accountInfo.data)
    : {};
  const [mintInfo, mintInfoLoaded] = useAccountInfo(mint);
  const { data: tokenInfo, isFetched: tokenInfoLoaded } = useQuery({
    queryKey: ['getMetadataByMint', connection.rpcEndpoint, mint?.toBase58()],
    queryFn: () => getMetadataByMint(connection, mint),
    meta: {
      persist: true,
    },
  });

  if (mint && mintInfoLoaded && tokenInfoLoaded) {
    try {
      let { decimals } = parseMintData(mintInfo.data);
      return {
        amount,
        decimals,
        mint,
        owner,
        tokenName: tokenInfo?.name,
        tokenSymbol: tokenInfo?.symbol,
        tokenLogoUri: tokenInfo?.uri?.replace(
          /^[\s\uFEFF\xA0\0]+|[\s\uFEFF\xA0\0]+$/g,
          '',
        ),
        valid: true,
      };
    } catch (e) {
      return {
        amount,
        decimals: 0,
        mint,
        owner,
        tokenName: 'Invalid',
        tokenSymbol: 'INVALID',
        tokenLogoUri: null,
        valid: false,
      };
    }
  }

  if (!mint) {
    return {
      amount: accountInfo?.lamports ?? 0,
      decimals: 9,
      mint: null,
      owner: publicKey,
      tokenName: 'DOMI',
      tokenSymbol: 'DOMI',
      valid: true,
    };
  }

  return null;
}

export function useWalletSelector() {
  const {
    accounts,
    derivedAccounts,
    addAccount,
    setWalletSelector,
    setAccountName,
    hardwareWalletAccount,
    setHardwareWalletAccount,
  } = useContext(WalletContext);

  return {
    accounts,
    derivedAccounts,
    setWalletSelector,
    addAccount,
    setAccountName,
    hardwareWalletAccount,
    setHardwareWalletAccount,
  };
}
