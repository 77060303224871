import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import strings from '../../../localization';

const BASE_URL = 'https://airdrop1.domichain.io';
const DECIMALS = 1_000_000_000; 

const useStyles = makeStyles((theme) => ({
  rewardInfo: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  green: {
    color: '#4caf50'
  },
  red: {
    color: '#f44336'
  },
  grey: {
    color: '#9e9e9e'
  },
  warning: {
    color: '#ff9800',
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5)
  }
}));

export function RewardInfo({ amount, walletAddress }) {
  const classes = useStyles();
  const [purchasedAmount, setPurchasedAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  const convertToDomi = (lamports) => {
    return lamports / DECIMALS;
  };

  const convertToLamports = (domi) => {
    return domi * DECIMALS;
  };

  /* Test
  useEffect(() => { 
    const testWallet = "7YBTmPj1EV1yNKyPjxA7TaipfYU81pAajd6UvpjnX5wf";
    const fetchTestWallet = async () => {
      try {
        console.log('Testing API with known wallet:', testWallet);
        const response = await fetch(`${BASE_URL}/v1/get-total-exchanges-amount/${testWallet}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'
          }
        });

        console.log('Test wallet response status:', response.status);
        
        if (!response.ok) {
          throw new Error(`Test wallet HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Test wallet API Response:', data);

        const rawAmount = parseInt(data.data.total_amount, 10) || 0;
        const domiAmount = convertToDomi(rawAmount);
        console.log('Parsed purchased amount (DOMI):', domiAmount);
        setPurchasedAmount(domiAmount);
      } catch (error) {
        console.error('Test wallet fetch error:', error);
      }
    };

    fetchTestWallet();
  }, []); 
  */

  // Main 
  useEffect(() => {
    const fetchPurchasedAmount = async () => {
      if (!walletAddress) return;
      
      try {
        const url = `${BASE_URL}/v1/get-total-exchanges-amount/${walletAddress}`;
        //console.log('Fetching from:', url);

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        const rawAmount = parseInt(data.data.total_amount, 10) || 0;
        const domiAmount = convertToDomi(rawAmount);
        //console.log('Parsed purchased amount (DOMI):', domiAmount);
        setPurchasedAmount(domiAmount);
        
      } catch (error) {
        console.error('Fetch error:', error);
        setPurchasedAmount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedAmount();
  }, [walletAddress]);

  if (loading || !amount || parseFloat(amount) < 0) {
    return null;
  }

  const parsedAmount = parseFloat(amount);
  const isNewlyPurchased = parsedAmount <= purchasedAmount;
  const colorClass = isNewlyPurchased ? classes.green : classes.red;
  const shouldShowWarning = purchasedAmount > 0 && !isNewlyPurchased;

  return (
    <>
      <div className={classes.rewardInfo}>
        <Typography variant="body2">
          <span className={classes.grey}>{strings.dailyWithdrawal}: </span>
          <span className={colorClass}>{isNewlyPurchased ? '0.47%' : '0.2%'}</span>
        </Typography>
        <Typography variant="body2">
          <span className={classes.grey}>{strings.totalReward}: </span>
          <span className={colorClass}>{isNewlyPurchased ? '100%' : '50%'}</span>
        </Typography>
      </div>
      {shouldShowWarning && (
        <Typography className={classes.warning}>
          {strings.stakeWarning}
        </Typography>
      )}
    </>
  );
}