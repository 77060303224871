import { DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Box, InputAdornment } from '@mui/material';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
} from '@solana/web3.js';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import StakingService, { StakingMode } from '../../../services/stakingService';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import StakingSettingsDialog from '../StakingSettingsDialog';

export function DelegateStake({
  votePubkey,
  stakingBackendUrl,
  stakingLimit,
  onClose,
  lockupPeriodSeconds,
  stakingMode,
}: {
  votePubkey: string;
  stakingBackendUrl: string;
  stakingLimit: number;
  onClose: () => void;
  lockupPeriodSeconds?: number | undefined;
  stakingMode: StakingMode;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const balanceInfo = useBalanceInfo(wallet.publicKey);
  const queryClient = useQueryClient();

  const [sendTransaction, sending] = useSendTransaction();

  const [tokenAmount, setTokenAmount] = useState<string>('');

  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);

  const delegateStake = async () => {
    const stakeAccount = await stakingService.getStakeAccount();
    const votePubkey2 = new PublicKey(votePubkey);

    return await stakingService.delegateStake(stakeAccount, tokenAmount, stakingLimit,
      votePubkey2, lockupPeriodSeconds);
  };

  const handleOnPress = async () => {
    sendTransaction(delegateStake(), {
      onSuccess: async () => {
        const stakeAccount = await stakingService.getStakeAccount();
        try {
          const { stakeAmount } = await stakingService.registerStake(stakeAccount);

          localStorage.setItem(
            `initialStake_${stakeAccount.toBase58()}`,
            stakeAmount.toString(),
          );
        } finally {
          queryClient.refetchQueries({
            queryKey: ['stakeAccountInfo', stakeAccount.toBase58()],
          });
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.startStaking}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {strings.stakingDelegateDescription}
        </DialogContentText>

        <TextField
          label={strings.amount}
          fullWidth
          autoFocus
          variant="outlined"
          margin="normal"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={() =>
                    setTokenAmount(
                      balanceAmountToUserAmount(
                        Math.max(0, balanceInfo?.amount - LAMPORTS_PER_SOL),
                        balanceInfo?.decimals,
                      ),
                    )
                  }
                >
                  {strings.max}
                </Button>
                <Typography color="primary">
                  {balanceInfo?.tokenSymbol}
                </Typography>
              </InputAdornment>
            ),
            inputProps: {
              step: Math.pow(10, -balanceInfo?.decimals),
            },
          }}
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value.trim())}
          helperText={
            <span
              onClick={() =>
                setTokenAmount(
                  balanceAmountToUserAmount(
                    balanceInfo?.amount,
                    balanceInfo?.decimals,
                  ),
                )
              }
            >
              {strings.max}:{' '}
              {balanceAmountToUserAmount(
                balanceInfo?.amount,
                balanceInfo?.decimals,
              )}
            </span>
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.back}</Button>
        <Button color="primary" onClick={handleOnPress} disabled={sending}>
          {strings.stake}
        </Button>
      </DialogActions>
    </>
  );
}

function balanceAmountToUserAmount(balanceAmount: number, decimals: number) {
  return (balanceAmount / Math.pow(10, decimals)).toFixed(decimals);
}
