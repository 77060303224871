export const NODE_URL = process.env.REACT_APP_NODE_URL;
export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL;
export const STAKING_BACKEND_URL = process.env.REACT_APP_STAKING_URL;
export const AIRDROP_BACKEND_URL = process.env.REACT_APP_AIRDROP_URL;
export const USDT_BRIDGE_URL = process.env.REACT_APP_USDT_BRIDGE_URL;
export const USDT_BRIDGE_SERVICE_ADDRESS =
  process.env.REACT_APP_USDT_BRIDGE_SERVICE_ADDRESS;
export const BTC_BRIDGE_URL = process.env.REACT_APP_BTC_BRIDGE_URL;
export const BTC_BRIDGE_SERVICE_ADDRESS =
  process.env.REACT_APP_BTC_BRIDGE_SERVICE_ADDRESS;
export const TELEGRAM_SUBSCRIBER_BOT_URL = process.env.REACT_APP_TELEGRAM_SUBSCRIBER_BOT_URL;
export const TELEGRAM_BOT_BACKEND_URL = process.env.REACT_APP_TELEGRAM_BOT_BACKEND_URL;
export const REFERRAL_BACKEND_URL = process.env.REACT_APP_REFERRAL_BACKEND_URL;
export const REFERRAL_BASE_URL = process.env.REACT_APP_REFERRAL_BASE_URL;

export const DOMICHAIN_TWITTER_URL = process.env.REACT_APP_DOMICHAIN_TWITTER_URL;

export const DEBUG_SKIP_PWA_INSTALL = process.env.REACT_APP_DEBUG_SKIP_PWA_INSTALL === 'true';
