import { useState } from 'react';
import { Box } from '@mui/material';
import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  StakeActivationData,
} from '@solana/web3.js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import strings from '../../../localization';
import StakingService, { StakingMode } from '../../../services/stakingService';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { abbreviateAddress, formatLamports, rewardsFormat } from '../../../utils/utils';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import StakingSettingsDialog from '../StakingSettingsDialog';
import UnstakeAlertDialog from './UnstakeAlertDialog';

export default function ActiveStakeInfo({
  stakeAccount,
  stakeAccountInfo,
  stakingBackendUrl,
  onClose,
  stakingMode,
}: {
  stakeAccount: PublicKey;
  stakeAccountInfo: StakeActivationData;
  stakingBackendUrl: string;
  onClose: () => void;
  stakingMode: StakingMode;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const queryClient = useQueryClient();
  const stakeAccountBalance = useBalanceInfo(stakeAccount);
  const [sendTransaction, sending] = useSendTransaction();

  const [showAlert, setShowAlert] = useState(false);

  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);

  const { data: activeStakeInfo, refetch: refetchActiveStakeInfo } = useQuery({
    queryKey: [
      'activeStakeInfo',
      wallet.publicKey?.toBase58(),
      stakeAccount.toBase58(),
      stakingBackendUrl,
    ],
    queryFn: async () => {
      return await stakingService.fetchActiveStakeInfo(stakeAccount);
    },
    retry: false,
  });

  const { data: inflationReward } = useQuery({
    queryKey: ['getInflationReward', stakeAccount.toBase58()],
    queryFn: async () => {
      const result = await connection.getInflationReward([stakeAccount]);
      return result?.[0];
    },
    enabled: Boolean(stakeAccountInfo),
    refetchInterval: 60 * 1000,
  });

  const deregisterStake = async () => {
    try {
      await stakingService.deregisterStake(stakeAccount);
    } catch (error) {
      console.warn(`Failed to delete active stake info: `, error);
    } finally {
      refetchActiveStakeInfo();
    }
  };

  const refreshStakeAccountInfo = () => {
    queryClient.refetchQueries({
      queryKey: ['stakeAccountInfo', stakeAccount.toBase58()],
    });
  };

  const handleOnPressUnstake = () => {
    sendTransaction(stakingService.deactivateStake(stakeAccount), {
      onSuccess: refreshStakeAccountInfo,
    });
  };

  const handleOnPressWithdraw = () => {
    sendTransaction(stakingService.withdrawStake(stakeAccount), {
      onSuccess: () => {
        deregisterStake();
        refreshStakeAccountInfo();
        onClose();
      },
    });
  };

  const initialStake = parseInt(
    activeStakeInfo?.stakeAmount ??
    localStorage.getItem(`initialStake_${stakeAccount.toBase58()}`),
  );

  const reward = !!initialStake
    ? stakeAccountInfo.active + stakeAccountInfo.inactive - initialStake
    : inflationReward?.amount ?? 0;

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.yourStake}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          { // @ts-ignore
            match(stakeAccountInfo)
            .with(
              { state: 'activating' },
              () => strings.stakingActivatingDescription,
            )
            .otherwise(() => strings.stakingActiveDescription)}
        </DialogContentText>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableBody>
              {[
                [strings.stakeAccount, abbreviateAddress(stakeAccount)],
                [
                  strings.status,
                  // @ts-ignore
                  match(stakeAccountInfo.state)
                    .with('activating', () => strings.activating)
                    .with('active', () => strings.active)
                    .with('deactivating', () => strings.deactivating)
                    .with('inactive', () => strings.inactive)
                    .exhaustive(),
                ],
                [
                  strings.balance,
                  `${formatLamports(
                    inflationReward?.postBalance ?? stakeAccountBalance?.amount,
                  )} DOMI`,
                ],
                [
                  strings.activeStake,
                  `${formatLamports(stakeAccountInfo?.active)} DOMI`,
                ],
                [
                  strings.inactiveStake,
                  `${formatLamports(stakeAccountInfo?.inactive)} DOMI`,
                ],
                [
                  strings.reward,
                  `${rewardsFormat.format(reward / LAMPORTS_PER_SOL)} DOMI`,
                ],
              ].map(([name, value]) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">
                    <b>{value}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>

        {
          // @ts-ignore
          match(stakeAccountInfo)
          .with(
            // @ts-ignore
            { state: P.union('activating', 'active') }, () => (
              <Button
                color="primary"
                onClick={() => setShowAlert(true)}
                disabled={sending}
              >
                {strings.unstake}
              </Button>
          ))
          .with(
            // @ts-ignore
            { state: P.union('deactivating', 'inactive').select() },
            (state) => (
              <Button
                color="primary"
                onClick={handleOnPressWithdraw}
                disabled={state === 'deactivating' || sending}
              >
                {strings.withdrawStake}
              </Button>
            ),
          )
          .otherwise(() => null)}
      </DialogActions>

      <UnstakeAlertDialog open={showAlert} onConfirm={() => {
        handleOnPressUnstake();
        setShowAlert(false);
      }} onClose={() => setShowAlert(false)} />
    </>
  );
}
