import strings from '../localization';
import { REFERRAL_BASE_URL } from '../utils/env-variables';
import ReferralAPI from './referralAPI';


const REF_LINK_REGEX = /^https:\/\/ref\.domichain\.io\?ref=(?<sponsor>[a-zA-Z0-9]+)$/;

const referralAPI = new ReferralAPI();

export default class ReferralService {
  async registerReferral(domichainAddress: string): Promise<void> {
    console.log('Registering referral');

    const user = await this.retrieveUserDomi(domichainAddress);
    if (user === null) {
      await referralAPI.registerDomiUser(domichainAddress);
    }
  }

  async retrieveUserDomi(domichainAddress: string): Promise<any | null> {
    return await referralAPI.getDomiUser(domichainAddress);
  }

  async registerInvitedUser(refLink: string, email: string, domichainAddress: string): Promise<any> {
    const sponsorRef = this.extractSponsor(refLink);
    if (!sponsorRef) {
      throw new Error(strings.errorOccurredTryAgainLater);
    }

    return await referralAPI.registerDomiUser(domichainAddress, sponsorRef, email);
  }

  getReferralLink(referralId: string): string {
    return `${REFERRAL_BASE_URL}?ref=${referralId}`;
  }

  // https://ref.domichain.io?ref=12345
  extractSponsor(refLink: string): string | null {
    const found = refLink.match(REF_LINK_REGEX);
    if (!found) {
      return null;
    }
    return found.groups?.sponsor || null;
  }
}
